.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.title {
  font-size: 50px;
  font-weight: 100;
  text-align: center;
}

.content {
  font-weight: 100;
  font-size: 20px;
  text-align: center;
}

.article {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px;
  margin: 0 auto;
}

.link {
  font-weight: bold;
}

.link:hover {
  text-decoration: none;
}

.logo {
  width: 100px;
  margin: 2em auto;
  margin-top: -10em;
}

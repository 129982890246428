/* @import url("https://fonts.googleapis.com/css2?family=Mitr:wght@300&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: Mitr, system-ui; */
  font-family: Kanit, system-ui;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
}
.form-group.invalid, .form-span.invalid{
  label, legend{
      color: #ff6f6f !important;
      border-color: #ff6f6f;
  }

  fieldset{
      border-color: #ff6f6f;
  }

  select{
      border-color: #ff6f6f;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
